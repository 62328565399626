//NAR Settlement Video

import React, { useState } from "react";
import "./style.css";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomFooter from "components/CustomFooter/CustomFooter.js";
//import { Link } from "react-router-dom";
//import Button from "components/CustomButtons/Button.js";

export default function WvrCareersMain() {
  const [youtubeID] = useState("bFzZpDA7a6g");
  return (
    <div className="pageDiv">
      <Header
        brand="WeVett Realty"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 80,
          color: "secondary",
        }}
      />
      <div id="video-main-content" className="video-main">
        <div id="video-section" className="section">
          <div className="boxed-800 div flex flex-row">
            <div class="title-div flex">
              <h1>NAR Settlement</h1>
            </div>
            <div class="video-div flex flex-row justify-center">
              <iframe
                className="video"
                width="640"
                height="390"
                title="Youtube player"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/${youtubeID}?autoplay=0&origin=https://wevettrealty.com&rel=0`}
              ></iframe>
              <hr class="hide"></hr>
            </div>
            <div class="transcript-div flex flex-column align-left">
              <h2>
                <strong>
                  Understanding the NAR Real Estate Lawsuit and What It Means
                  for Military Buyers and Sellers
                </strong>
              </h2>
              <p>
                Recently, there’s been a lot of confusion surrounding the
                National Association of Realtors (NAR) real estate lawsuit,
                especially for military buyers and sellers. Let's break it down
                simply to help you understand how this could impact you.
              </p>
              <h3>
                <strong>The NAR Lawsuit Overview</strong>
              </h3>
              <p>
                Last year, a high-profile lawsuit known as the
                <strong> Sitzer Burnett Class Action Litigation</strong> made
                headlines. This case began with home sellers in Missouri who
                were upset about how much they had to pay the buyer’s agent
                during their home sale, even though they had hired a listing
                agent. In simpler terms, they felt that the compensation
                structure for buyer’s agents wasn't fully clear from the
                beginning, and they argued they should only be responsible for
                paying their own agent.
              </p>
              <p>
                The lawsuit was filed against Realtors and the NAR, which is
                significant because the outcome of this case applies to Realtors
                nationwide. It’s important to note that{" "}
                <strong>Realtors</strong> are a specific group of real estate
                agents held to higher ethical standards. While all Realtors are
                real estate agents, not all real estate agents are Realtors.
              </p>
              <h3>
                <strong>The Proposed Settlement and 2024 Changes</strong>
              </h3>
              <p>
                On March 15, 2024, NAR announced that it had reached a proposed
                settlement in the lawsuit, and this settlement required two
                major changes in the real estate process, which took effect
                August 17, 2024:
              </p>
              <ol>
                <li>
                  <p>
                    <strong>
                      Prohibition of Compensation Offers on MLS Listings
                    </strong>
                    : The Multiple Listing Service (MLS), the platform Realtors
                    use to list homes for sale, will no longer allow the listing
                    of compensation offers for buyer’s agents. This change is
                    designed to clarify that compensation is not dictated by law
                    but is instead determined through individual negotiations.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Written Agreement for Buyer’s Agents</strong>:
                    Buyer’s agents will be required by law to have a written
                    agreement with their clients before showing any homes. This
                    agreement must clearly outline the compensation terms.
                  </p>
                </li>
              </ol>
              <h3>
                <strong>What Does This Mean for Sellers?</strong>
              </h3>
              <p>
                If you're selling your home, these changes don’t directly affect
                how you list your property or how you compensate your real
                estate agent. Commissions have always been negotiable, and
                you’ve always had the flexibility to negotiate the amount you’ll
                pay your agent — whether it’s a small or large percentage of the
                sale price.
              </p>
              <p>
                Traditionally, listing agents have shared part of this
                commission with the buyer’s agent at closing, making it easier
                for more buyers to see your property. With the new changes, you
                may not see compensation listed in the MLS, but it can still be
                communicated in other ways like through personal websites,
                flyers, text messages, or phone calls.
              </p>
              <p>
                However, this adjustment ensures that compensation is not
                automatically assumed and is based on individual agreements.
              </p>
              <h3>
                <strong>What Does This Mean for Buyers?</strong>
              </h3>
              <p>
                For buyers, the biggest change is that you will now be required
                to sign a written agreement with your agent before you start
                viewing homes. This agreement must include four key points:
              </p>
              <ol>
                <li>
                  <p>
                    <strong>Compensation Amount</strong>: The agreement must
                    clearly state how much or what percentage your agent will be
                    paid and how it will be determined. This amount cannot be
                    vague.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Specificity</strong>: The compensation rate must be
                    specific and not open-ended. For example, a vague “whatever
                    the seller is offering” is not acceptable.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>No Overpayment</strong>: If your buyer’s agent is
                    set to receive a specific percentage in your agreement, they
                    cannot collect more than that, even if the seller offers a
                    higher amount to the buyer's agent.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Negotiability of Commissions</strong>: The agreement
                    must include a statement that broker fees and commissions
                    are negotiable, which has always been the case.
                  </p>
                </li>
              </ol>
              <p>
                If a seller chooses not to offer compensation to the buyer's
                agent, then you as the buyer would be responsible for paying
                your agent directly.
              </p>
              <h3>
                <strong>Impact on Military Buyers Using VA Loans</strong>
              </h3>
              <p>
                For military buyers, particularly those using VA loans, the
                situation is a bit different. Previously, VA loan guidelines did
                not allow buyers to pay their buyer’s agent directly to protect
                Veterans from being taken advantage of. However, the{" "}
                <strong>VA has made a temporary amendment</strong> to allow
                military and Veterans to pay their agent directly.
              </p>
              <h3>
                <strong>Will Sellers Continue to Pay Buyer’s Agents?</strong>
              </h3>
              <p>
                Even with these changes, many sellers are likely to continue
                compensating buyer’s agents. Why? Simply put, offering
                compensation to the buyer's agent increases the likelihood of
                the property selling for a higher price. The increased exposure
                and likelihood of a sale benefit both parties.
              </p>
              <p>
                While this legal shift will bring more transparency to the
                process, it is unlikely to cause drastic changes in the market
                immediately. Most areas will likely continue to see compensation
                for buyer’s agents offered by sellers.
              </p>
              <h3>
                <strong>Looking Ahead</strong>
              </h3>
              <p>
                Will these changes become permanent or evolve over time? It’s
                hard to say. However, for now, the real estate market will
                continue operating much as it did before, with the added benefit
                of more transparency about how agents are compensated during
                transactions.
              </p>
              <p></p>
              <hr />
              <p></p>
              <p>
                <strong>About the Author</strong>
              </p>
              <p>
                Shannon Kaufman is the owner of <strong>WeVett Realty</strong>,
                a company that helps military buyers and sellers connect with
                specialized real estate agents. Her team works with agents
                nationwide to ensure that military families receive the best
                possible service during their home buying and selling
                experience.
              </p>
              <p>
                If you have any questions or need assistance, feel free to reach
                out. We’re here to help!
              </p>
            </div>
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
